var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "relative flex" },
      [
        _c("asterix-input", {
          staticClass: "flex-1 currency-value",
          attrs: {
            value: _vm.amount,
            "auto-validate": _vm.autoValidate,
            "class-input": _vm.customClassInput,
            type: "number",
            placeholder: _vm.placeholder,
            min: _vm.min,
            max: _vm.max,
            maxlength: _vm.maxlength,
            "maxlength-error": _vm.maxlengthError,
            error: _vm.error,
            required: _vm.required,
            disabled: _vm.disabled,
            pattern: _vm.pattern,
            "pattern-error-message": _vm.patternErrorMessage,
            "validate-on-blur": _vm.validateOnBlur,
          },
          on: { input: _vm.onAmountChange },
        }),
        _vm.currencyOptions.length > 0
          ? _c("asterix-dropdown", {
              staticClass:
                "absolute right-0 flex items-center w-16 h-10 appearance-none asterix-dropdown focus:outline-none focus:shadow-outline focus:bg-blue-100",
              attrs: {
                disabled: _vm.currencyDisabled || _vm.disabled,
                options: _vm.currencyOptions,
                value: _vm.currency,
              },
              on: { change: _vm.onCurrencyChange },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm.currency
                          ? _c("span", {
                              staticClass: "text-xs focus:bg-blue-900",
                              class: _vm.currencyDisabled
                                ? "text-gray-400"
                                : "text-gray-700",
                              domProps: {
                                textContent: _vm._s(_vm.currency.id),
                              },
                            })
                          : _c(
                              "p",
                              {
                                class: _vm.currencyDisabled
                                  ? "text-gray-400"
                                  : "text-gray-700",
                              },
                              [_vm._v("-")]
                            ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm.currency
                    ? {
                        key: "option",
                        fn: function ({ option }) {
                          return [
                            option
                              ? _c("p", {
                                  staticClass:
                                    "w-full px-3 text-xs font-bold hover:text-white hover:bg-gray-700 rounded-full",
                                  class: _vm.getSelectedClasses(option),
                                  domProps: {
                                    textContent: _vm._s(option.option.labelIso),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }