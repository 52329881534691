<template>
  <div>
    <div class="relative flex">
      <asterix-input
        :value="amount"
        :auto-validate="autoValidate"
        class="flex-1 currency-value"
        :class-input="customClassInput"
        type="number"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        :maxlength-error="maxlengthError"
        :error="error"
        :required="required"
        :disabled="disabled"
        :pattern="pattern"
        :pattern-error-message="patternErrorMessage"
        :validate-on-blur="validateOnBlur"
        @input="onAmountChange"
      />

      <asterix-dropdown
        v-if="currencyOptions.length > 0"
        class="absolute right-0 flex items-center w-16 h-10 appearance-none asterix-dropdown focus:outline-none focus:shadow-outline focus:bg-blue-100"
        :disabled="currencyDisabled || disabled"
        :options="currencyOptions"
        :value="currency"
        @change="onCurrencyChange"
      >
        <template #default>
          <span
            v-if="currency"
            class="text-xs focus:bg-blue-900"
            :class="currencyDisabled ? 'text-gray-400' : 'text-gray-700'"
            v-text="currency.id"
          ></span>
          <p v-else :class="currencyDisabled ? 'text-gray-400' : 'text-gray-700'">-</p>
        </template>
        <template v-if="currency" #option="{ option }">
          <p
            v-if="option"
            class="w-full px-3 text-xs font-bold hover:text-white hover:bg-gray-700 rounded-full"
            :class="getSelectedClasses(option)"
            v-text="option.option.labelIso"
          ></p>
        </template>
      </asterix-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import { GET_CURRENCIES_REQUEST, CURRENCIES } from '@/store/modules/currency/keys';
import AsterixInput from '@/components/atoms/AsterixInput';

export default {
  name: 'CurrencySelector',
  components: {
    AsterixDropdown,
    AsterixInput,
  },
  props: {
    min: {
      type: Number,
      default: () => 0,
    },
    max: {
      type: Number,
      default: () => 1000000000,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    currencyDisabled: {
      type: Boolean,
      default: false,
    },
    defaultCurrency: {
      type: String,
      default: () => null,
    },
    currencies: {
      type: [Array, null],
      default: () => null,
    },
    value: {
      type: Object,
      default: () => ({ value: null, currency: null }),
    },
    classInput: {
      type: [Object, String],
      default: () => '',
    },
    error: {
      type: [String, Boolean],
      default: () => false,
    },
    pattern: {
      type: String,
      default: () => '',
    },
    patternErrorMessage: {
      type: String,
      default: () => '',
    },
    validateOnBlur: {
      type: Boolean,
      default: () => true,
    },
    autoValidate: {
      type: Boolean,
      default: () => false,
    },
    maxDecimals: {
      type: Number,
      default: () => 2,
    },
  },
  data: () => ({
    internalError: null,
    amount: null,
    currency: {},
    MAX_LENGTH_DEFAULT: 100,
    maxlength: 100,
  }),
  computed: {
    ...mapGetters({ currencyList: CURRENCIES }),
    customClassInput() {
      return [this.classInput, 'pr-16'];
    },
    showError() {
      return !!this.internalError;
    },
    currencyOptions() {
      return this.currencies || this.currencyList;
    },
    maxlengthError() {
      return `${this.maxDecimals} decimals max`;
    },
  },
  watch: {
    defaultCurrency: 'setCurrency',
    currencyOptions: {
      deep: true,
      handler() {
        this.setCurrency(this.value?.currency || this.defaultCurrency);
      },
    },
    value: 'setValue',
  },
  async created() {
    if (this.currencies === null) {
      await this.getCurrencies();
    }
    this.setValue(this.value);
  },
  methods: {
    ...mapActions({ getCurrencies: GET_CURRENCIES_REQUEST }),
    getSelectedClasses({ option }) {
      return option && option.id === this.currency.id ? 'text-white bg-gray-700' : 'text-gray-500';
    },
    onBlurChange({ value }) {
      if (!this.currencyDisabled && value && !this.currency) {
        this.internalError = 'You must pick a currency value';
      } else {
        this.internalError = null;
      }
    },
    setValue(value) {
      this.amount = value?.value === 0 || value?.value ? value?.value : null;
      this.setCurrency(value?.currency || this.defaultCurrency);
    },
    setCurrency(currency = this.defaultCurrency) {
      if (typeof currency === 'string') {
        this.currency = this.findCurrency(currency);
      } else {
        this.currency = currency;
      }
    },
    findCurrency(currencyId) {
      return this.currencyOptions.find(currency => currency.id === currencyId);
    },
    emit() {
      this.$emit('input', { value: this.amount || 0, currency: this.currency });
    },
    onCurrencyChange(event) {
      this.currency = event;
      this.internalError = null;
      this.emit();
    },
    onAmountChange(value) {
      if (value.toString().includes('.')) {
        const index = value.toString().indexOf('.');
        this.maxlength = index + this.maxDecimals + 1;
      } else {
        this.maxlength = this.MAX_LENGTH_DEFAULT;
      }
      this.amount = value;
      this.emit();
    },
  },
};
</script>

<style scoped>
.asterix-dropdown {
  position: absolute;
}
</style>
